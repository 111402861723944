import React from 'react';
import { Link } from 'react-router-dom';

const Navbar = () => {
  return (
    <div className='bg-gray-100  '>
      <div className='max-w-7xl mx-auto px-8 sm:px-8 lg:px-16'>
        <div className='flex justify-between items-center py-8'>
          <Link to="/" className='text-l font-sans font-bold text-[#15803d] truncate'>
          ΛCOUNT
          </Link>
          <div className='flex items-right'>
            <ul className='flex'>
              <li>
                <Link to="/merchant-login" className="flex items-right">
                  <button className='text-black bg-gray-100 py-2 px-0 rounded-lg font-semibold text-base flex items-right whitespace-nowrap'>
                    Sign in 
                  </button>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
