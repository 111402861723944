import React from 'react';

const MerchantLogin = () => {
  const handleGetStarted = () => {
    window.location.href =
      "mailto:info@withacount.com?subject=Company%20name%20here&body=Full%20Name:%0A%0ACompany%20Name:%0A%0ACompany%20Website:%0A";
  };

  return (
    <div className='py-12'>
      <div className="max-w-2xl mx-auto px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-3xl font-bold tracking-tight mb-6">
            Merchant Login
          </h2>
          <p className="text-lg leading-8 mb-8 lg:mb-12">
            We are currently busy building 🏗️ <br />
            Please request access to join our waitlist.
          </p>
          <LoginButton onClick={handleGetStarted} />
        </div>
      </div>
    </div>
  );
};

const LoginButton = ({ onClick }) => {
  return (
    <button 
      className='bg-[#15803d] w-[200px] hover:bg-[#16a34a] rounded-md font-medium py-3 px-6 text-white'
      onClick={onClick}
    >
      Request access 
    </button>
  );
};

export default MerchantLogin;
