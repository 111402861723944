import React from 'react';
import { Link } from 'react-router-dom';
import frame44 from '../images/Frame 44.png'; // Import Frame 44 image

const stats = [
  { id: 1, name: '01 Simple Integration', value: 'Secure self-implemented integration to your commerce stack ensuring high conversion.' },
  { id: 2, name: '02 Instant Payments', value: 'Direct payments with low fixed fee resulting significant cost savings compared to cards.' },
  { id: 3, name: '03 MitID Verification', value: 'Built on EU banks PSD2 open banking infrastructure with MitID SCA in Denmark.' },
];

const Navbar = () => {
  return (
    <div className='w-full bg-[#ffffff] border-b border-gray-200'>
      <div className='max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-[#3f3f46]'>
        <div className='flex justify-between items-center h-24'>
          <Link to="/" className='text-lg md:text-xl lg:text-2xl font-bold text-[#15803d] truncate'>
            acount
          </Link>
          <div className='flex items-center'>
            <ul className='flex'>
              <li>
                <Link to="/merchant-login" className="flex items-center">
                  <button className='text-[#000000] py-2 px-4 rounded-lg font-semibold text-sm flex items-center whitespace-nowrap'>
                    Sign in 
                  </button>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

const Hero = () => {
  const handleGetStarted = () => {
    window.location.href = "mailto:info@withacount.com?subject=Company%20name%20here&body=Full%20Name:%0A%0ACompany%20Name:%0A%0ACompany%20Website:%0A";
  };

  return (
    <div className="w-full bg-gray-100">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 sm:py-12 text-[#3f3f46]">
        <div className="flex flex-col sm:flex-row items-center justify-between"> {/* Container for frames */}
          <div className="sm:w-1/2 pr-6 sm:pr-10 pl-6 sm:pl-10 mb-6 sm:mb-0 flex flex-col justify-center"> {/* Adjusted width and padding for desktop view */}
            <div>
              <h1 className="text-3xl md:text-5xl font-bold tracking-tight justify center sm:justify:start text-gray-900">
                Accept real-time <br></br> direct bank payments
              </h1>
              <p className="mt-4 sm:mt-6 text-base sm:text-lg leading-8 text-gray-600">
              Secure real-time payments for acceptance and refunds directly between bank accounts with EU wide coverage.
              </p>
            </div>

            <div className="mt-8 sm:mt-10 flex flex-wrap gap-1 sm:gap-8 justify-center sm:justify-start">
              <button 
                className='rounded-md bg-[#15803d] px-6 sm:px-8 py-3 text-sm font-semibold text-white shadow-sm hover:bg-[#16a34a] focus:outline focus:outline-2 focus:outline-offset-2 focus:outline-indigo-600 w-full sm:w-auto'
                onClick={handleGetStarted}
              >
                Request access
              </button>

              <a href="mailto:info@withacount.com" className="mt-4 sm:mt-0 text-sm font-semibold leading-6 text-gray-900 flex items-center py-3 w-full sm:w-auto sm:ml-4 md:ml-0 md:justify-start justify-center">
                Contact sales
              
              </a>
            </div>
          </div>
          
          <div className="sm:w-1/2 mx-auto sm:pl-10 flex items-center justify-center"> {/* Centered image on desktop */}
            {/* Frame 44 */}
            <img src={frame44} alt="Frame 44" className="h-auto max-w-full rounded-3xl" style={{ zIndex: 0 }} />
          </div>
        </div>

        {/* Stats Section */}
        <div className="max-w-7xl mx-auto mt-12 px-4 sm:px-6 lg:px-8"> {/* Adjusted padding for stats */}
          <div className="flex flex-col sm:flex-row gap-8 sm:gap-20">
            {stats.map((stat) => (
              <div key={stat.id} className="mb-6 sm:mb-0 sm:w-1/3">
                <p className="text-base md:text-lg font-semibold tracking-tight text-[#15803d]">{stat.name}</p>
                <p className="mt-2 text-sm md:text-base leading-7 text-gray-600">{stat.value}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
export { Navbar };
