import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import Footer from './components/Footer';
import PrivacyPolicy from './components/PrivacyPolicy';
import MerchantLogin from './components/MerchantLogin';
import './App.css'; // Assuming you have an App.css for styling

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  return isLoggedIn ? (
    <Router>
      <div className="flex flex-col min-h-screen bg-gray-100">
        <header>
          <Navbar />
        </header>
        <main className="flex-grow">
          <Routes>
            <Route path="/" element={<Hero />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/merchant-login" element={<MerchantLoginWithMargin />} />
          </Routes>
        </main>
        <footer>
          <Footer />
        </footer>
      </div>
    </Router>
  ) : (
    <LoginPage onLogin={() => setIsLoggedIn(true)} />
  );
}

// Create a wrapper component to add the margin to MerchantLogin
const MerchantLoginWithMargin = () => (
  <div className="mt-16">
    <MerchantLogin />
  </div>
);

const LoginPage = ({ onLogin }) => {
  const [password, setPassword] = useState('');
  const handleSubmit = (e) => {
    e.preventDefault();
    if (password === 'Andromeda@4202') {
      onLogin();
    } else {
      alert('Incorrect password');
    }
  };

  return (
    <div className="login-container">
      <form onSubmit={handleSubmit} className="login-form">
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Enter password"
          className="login-input"
        />
        <button type="submit" className="login-button">Login</button>
      </form>
    </div>
  );
};

export default App;
