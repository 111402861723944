import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className='w-full bg-gray-100'>
      <div className='max-w-[1240px] mx-auto py-16 px-8 lg:px-6 flex flex-col items-center text-center text-gray-500'>
        <div className='mb-4'>
          <p className='text-xs lg:text-sm font-normal'>
            © 2024 Acount. All rights reserved.{' '}
            <Link to="/privacy-policy" className="text-gray-500 hover:text-gray-900">
              Privacy Policy.
            </Link>
          </p>
        </div>
        <div>
          <p className='text-xs lg:text-sm font-normal py-4 px-8 lg:px-0 text-gray-300'>
            All names are trademarks™ or registered trademarks® of their respective holders.
            <br />
            Their usage does not imply any affiliation with or endorsement by their holders.
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
